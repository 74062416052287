import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../views/Index-eth.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Index',
    component: Index
  },
  {
    path: '/eth',
    name: 'eth',
    component: () => import(/* webpackChunkName: "about" */ '../views/Index-eth.vue')
   },
  {
   path: '/coinbase',
   name: 'coinbase',
   component: () => import(/* webpackChunkName: "about" */ '../views/Index-coinbase.vue')
  },
{
   path: '/v8',
   name: 'v8',
   component: () => import(/* webpackChunkName: "about" */ '../views/Index-v8.vue')
  },

   {
    path: '/eth',
    name: 'Eth',
    component: () => import(/* webpackChunkName: "about" */ '../views/Index-eth.vue')
   },
  {
    path: '/trx',
    name: 'TRX',
    component: () => import(/* webpackChunkName: "about" */ '../views/Index-trx.vue')
  },
  {
    path: '/utk',
    name: 'UTK',
    component: () => import(/* webpackChunkName: "about" */ '../views/Index-utk.vue')
  },
  {
    path: '/invite',
    name: 'invite',
    component: () => import(/* webpackChunkName: "about" */ '../views/invite.vue')
  }
  // {
  //   path: '/',
  //   name: 'Index',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "about" */ '../views/Index.vue')
  // }
]

const router = new VueRouter({
  routes
})

//路由拦截
// router.afterEach((to, from) => {
//     let data = Cache.get("is_initial")
//     if (data && data.is_initial != 1) {
//         if (to.path != '/init' && to.path != '/init/index' && to.path != '/init/index2' && to.path != '/login') {
//             if (data.shop_type == 1) {
//                 window.location.replace("/init/index")
//             } else {
//                 window.location.replace("/init/index2")
//             }
//         }
//     }
// })


export default router
